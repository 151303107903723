import { format } from "date-fns";

export const convertToShortTimeAndDate = (dateAndTime) =>
  format(new Date(dateAndTime), "HH:mm, EEE dd MMM");

export const convertToShortMonth = (dateAndTime) =>
  format(new Date(dateAndTime), "dd MMM yyyy");

export const convertDateReverse = (dateAndTime) =>
  format(new Date(dateAndTime), "yyyy-MM-dd");

export const convertToMonth = (item) =>
  format(new Date(item.date_and_time), "MMMM yyyy");

export const convertTo24HrTime = (dateAndTime) =>
  format(new Date(dateAndTime), "HH:mm");

export const convertToShortDate = (dateAndTime) =>
  format(new Date(dateAndTime), "EEE dd MMM");

export const convertToDateWithMonthFirst = (dateAndTime) =>
  format(new Date(dateAndTime), "MMMM dd, yyyy");

export const convertToDateWithDayFirst = (dateAndTime) =>
  format(new Date(dateAndTime), "EEEE, dd MMMM yyyy");
