import Image from "next/legacy/image";
import { ImFilesEmpty } from "react-icons/im";
import { teamIcon } from "../../utils/constants/images";

import NoTextNavBackground from "./NoTextNavBackground";

const NoData = () => {
  return (
    <>
      <div className="text-center flex flex-col justify-center pt-20 sm:pt-40 xl:pt-60 2xl:pt-60">
        <ImFilesEmpty className="mx-auto h-12 w-12 text-gray-400" />

        <h3 className="mt-2 text-sm font-medium text-gray-900">No content</h3>
        <p className="mt-1 text-sm text-gray-500">
          Sorry, no content currently. Check back later.
        </p>
      </div>
    </>
  );
};

export default NoData;

export const NoDataFullPage = () => {
  return (
    <>
      <NoTextNavBackground />

      <div className="text-center flex flex-col justify-center pt-20 sm:pt-40 xl:pt-60 2xl:pt-60">
        <ImFilesEmpty className="mx-auto h-12 w-12 text-gray-400" />

        <h3 className="mt-2 text-sm font-medium text-gray-900">No content</h3>
        <p className="mt-1 text-sm text-gray-500">
          Sorry, no content currently. Check back later.
        </p>
      </div>
    </>
  );
};

export const TabNoData = () => {
  return (
    <>
      <div className="text-center flex flex-col justify-center pt-20 sm:mb-10">
        <ImFilesEmpty className="mx-auto h-12 w-12 text-gray-400" />

        <h3 className="mt-2 text-sm font-medium text-gray-900">No content</h3>
        <p className="mt-1 text-sm text-gray-500">
          Sorry, no content currently. Check back later.
        </p>
      </div>
    </>
  );
};

export const LiveMatchTabNoData = ({ message }) => {
  return (
    <>
      <div className="text-center flex flex-col justify-center pt-20 sm:mb-10">
        <ImFilesEmpty className="mx-auto h-12 w-12 text-gray-400" />

        <h3 className="mt-2 text-sm font-medium text-gray-900">No content</h3>
        <p className="mt-1 text-sm text-gray-500">{message}</p>
      </div>
    </>
  );
};

export const LineupNoData = () => {
  return (
    <>
      <div className="text-center flex flex-col justify-center pt-20 sm:mb-10">
        <Image
          src={teamIcon}
          alt="Team Icon"
          objectFit="contain"
          width="52px"
          height="52px"
          quality={100}
        />

        <p className="mt-6 text-sm text-gray-500">
          Line-ups will be available one hour to kick-off.
        </p>
      </div>
    </>
  );
};
