import Link from "next/link";

const MatchHighlightsButton = ({ highlightUrl }) => {
  return (
    (<Link
      href={highlightUrl}
      target="_blank"
      rel="noopener noreferrer"
      role="button"
      className="inline-flex items-center mt-5 sm:mt-0 px-4 py-3 border border-transparent text-sm sm:text-base font-medium rounded-md shadow-sm text-green-700 dark:text-red-600 bg-white hover:bg-green-50 dark:hover:bg-red-50 sm:px-8">

      <svg
        width="21"
        height="21"
        className="-ml-1 mr-2 h-5 w-5"
        viewBox="0 0 21 21"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.5 0.178711C4.70137 0.178711 0 4.70032 0 10.2772C0 15.8541 4.70137 20.3757 10.5 20.3757C16.2986 20.3757 21 15.8541 21 10.2772C21 4.70032 16.2986 0.178711 10.5 0.178711ZM10.5 18.4823C5.78813 18.4823 1.96875 14.8089 1.96875 10.2772C1.96875 5.74552 5.78813 2.07218 10.5 2.07218C15.2119 2.07218 19.0312 5.74552 19.0312 10.2772C19.0312 14.8089 15.2119 18.4823 10.5 18.4823ZM7.875 5.85912L15.75 10.2772L7.875 14.6953V5.85912Z"
          fill="currentColor"
        />
      </svg>View Highlights
    </Link>)
  );
};

export default MatchHighlightsButton;

export const FixtureHighlightsButton = ({ highlightUrl }) => {
  return (
    (<Link
      href={highlightUrl}
      target="_blank"
      rel="noopener noreferrer"
      role="button"
      className="inline-flex items-center mt-5 sm:mt-0 px-4 py-3 border border-transparent text-sm sm:text-base font-medium rounded-md shadow-sm  text-red-600 dark:text-green-700 bg-white dark:hover:bg-green-50 hover:bg-red-50 sm:px-8">

      <svg
        width="21"
        height="21"
        className="-ml-1 mr-2 h-5 w-5"
        viewBox="0 0 21 21"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.5 0.178711C4.70137 0.178711 0 4.70032 0 10.2772C0 15.8541 4.70137 20.3757 10.5 20.3757C16.2986 20.3757 21 15.8541 21 10.2772C21 4.70032 16.2986 0.178711 10.5 0.178711ZM10.5 18.4823C5.78813 18.4823 1.96875 14.8089 1.96875 10.2772C1.96875 5.74552 5.78813 2.07218 10.5 2.07218C15.2119 2.07218 19.0312 5.74552 19.0312 10.2772C19.0312 14.8089 15.2119 18.4823 10.5 18.4823ZM7.875 5.85912L15.75 10.2772L7.875 14.6953V5.85912Z"
          fill="currentColor"
        />
      </svg>View Highlights
    </Link>)
  );
};

export const FixtureHighlightsTextButton = ({ highlightUrl }) => {
  return (
    (<Link
      href={highlightUrl}
      target="_blank"
      rel="noopener noreferrer"
      role="button"
      className="inline-flex mt-5 items-center sm:mt-0  border border-transparent text-sm sm:text-base font-medium dark:text-green-700 text-red-600 dark:hover:text-green-500 hover:text-red-500">

      <svg
        width="21"
        height="21"
        className="-ml-1 mr-2 h-5 w-5"
        viewBox="0 0 21 21"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.5 0.178711C4.70137 0.178711 0 4.70032 0 10.2772C0 15.8541 4.70137 20.3757 10.5 20.3757C16.2986 20.3757 21 15.8541 21 10.2772C21 4.70032 16.2986 0.178711 10.5 0.178711ZM10.5 18.4823C5.78813 18.4823 1.96875 14.8089 1.96875 10.2772C1.96875 5.74552 5.78813 2.07218 10.5 2.07218C15.2119 2.07218 19.0312 5.74552 19.0312 10.2772C19.0312 14.8089 15.2119 18.4823 10.5 18.4823ZM7.875 5.85912L15.75 10.2772L7.875 14.6953V5.85912Z"
          fill="currentColor"
        />
      </svg>Highlights
    </Link>)
  );
};
